import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Alberta({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-wrapper">
        <div
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
          className="formkit-bg-color"
        />
        <div
          style={styles.forElement("background")}
          className="formkit-bg-image"
        />
        <Elements.Video
          id="video"
          name="video"
          className="formkit-video"
          defaults={{
            src: "https://youtu.be/okspGoFx2F0"
          }}
        />
        <div
          className="formkit-content-container"
          style={styles.forElement("content_container")}
        >
          <div className="formkit-content-wrapper">
            <Elements.Heading
              className="formkit-heading"
              name="heading"
              defaults={{ content: "Are you ready for more art?" }}
            />
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content:
                    "<p>You're in the right place! On Patreon, I share behind the scenes insights into my process, create tutorials on how I create my art and market myself, give away pieces of my work, send you art, and even more! Only Patrons get these exclusive goodies.</p><p>It's all art, all day, every day, and I'd love for you to join us!</p>"
                }}
              />
            </Elements.Region>
          </div>
        </div>
        <div
          className="formkit-form-container"
          style={styles.forElement("form_container")}
        >
          <Elements.Heading
            className="formkit-form-heading"
            name="form_heading"
            tag="h2"
            defaults={{
              content: "Join Team Lizzie on Patreon today!"
            }}
          />
          <Elements.Form>
            <Elements.Errors />
            <Elements.CustomFields data-stacked={stackedDisplay}>
              {stackedDisplay && <Elements.AddFieldButton />}
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Send me the deets!" }}
              />
            </Elements.CustomFields>
            {!stackedDisplay && <Elements.AddFieldButton />}
          </Elements.Form>
          <Elements.Content
            className={`formkit-disclaimer ${!stackedDisplay && "formkit-max"}`}
            name="disclaimer"
            defaults={{
              content:
                "<p>We respect your privacy. Unsubscribe at any time.</p>"
            }}
          />
        </div>
        <Elements.BuiltWith background="background" image="background" />
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Alberta, { name: "Alberta" })
